<template>
  <form @submit.prevent="submit">
    <p class="subtitle">Адреса филиалов:</p>

    <template v-if="errorMessage">
      <p>{{ errorMessage }}</p>
    </template>

    <template v-if="successMessage">
      <p>{{ successMessage }}</p>
    </template>

    <div class="row">
      <div class="col-lg-12 mb-4">
        <input
          type="text"
          placeholder="Адрес филиала"
          class="personal__input personal__form-text"
          v-model="address"
          required
        >
      </div>

      <div class="col-lg-12">
        <input
          type="submit"
          value="Добавить филиал"
          class="btn btn--big personal__form-btn mt-0"
          v-if="!btnLoading"
        >

        <Preloader v-if="btnLoading"/>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'AddBranch',
  components: {
    Preloader,
  },
  data() {
    return {
      address: '',
      successMessage: '',
      errorMessage: '',
      btnLoading: false,
    };
  },
  computed: {
    checkForm() {
      return this.address;
    },
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    submit() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'addBranch');

        const params = new URLSearchParams();
        params.append('UF_ADDRESS', this.address);

        axios
          .post(url, params)
          .then((response) => {
            const { data } = response.data;

            if (data.success) {
              this.successMessage = data.message;

              setTimeout(() => {
                this.successMessage = '';
              }, 2000);

              this.$emit('input', true);
            } else {
              this.errorMessage = data.message;
            }

            this.address = '';
            this.btnLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorMessage = 'Ошибка!';
      }
    },
  },
};
</script>
