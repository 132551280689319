<template>
  <form @submit.prevent="submit">
    <div class="row align-items-end">
      <div class="col-lg-8">
        <template v-if="errorMessage">
          <p class="mb-0">{{ errorMessage }}</p>
        </template>

        <template v-if="successMessage">
          <p class="mb-0">{{ successMessage }}</p>
        </template>

        <input
          type="text"
          placeholder="Цена"
          class="personal__input personal__form-text"
          v-model="price"
          required
        >
      </div>

      <div class="col-lg-4">
        <div class="row" v-if="!btnLoading">
          <div class="col-lg-12">
            <a
              href="#"
              class="btn w-100 mb-2"
              @click.prevent="save()"
            >Изменить</a>
          </div>

          <div class="col-lg-12">
            <a
              href="#"
              class="btn w-100"
              @click.prevent="del()"
            >Удалить</a>
          </div>
        </div>

        <Preloader v-if="btnLoading"/>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'EditBranch',
  components: {
    Preloader,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      price: '',
      id: null,
      successMessage: '',
      errorMessage: '',
      btnLoading: false,
    };
  },
  computed: {
    checkForm() {
      return this.price;
    },
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    save() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'editPrice');

        const params = new URLSearchParams();
        params.append('ID', this.id);
        params.append('UF_PRICE', this.price);

        axios
          .post(url, params)
          .then((response) => {
            const { data } = response.data;

            if (data.success) {
              this.successMessage = data.message;

              setTimeout(() => {
                this.successMessage = '';
              }, 2000);
            } else {
              this.errorMessage = data.message;
            }

            this.btnLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorMessage = 'Ошибка!';
      }
    },
    del() {
      this.errorMessage = '';
      this.successMessage = '';

      this.btnLoading = true;

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'deletePrice');

      const params = new URLSearchParams();
      params.append('ID', this.id);

      axios
        .post(url, params)
        .then((response) => {
          const { data } = response.data;

          if (data.success) {
            this.$emit('delete', true);
          } else {
            this.errorMessage = data.message;
          }

          this.btnLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.price = this.item.price;
    this.id = this.item.id;
  },
};
</script>
