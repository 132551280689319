<template>
  <section class="tabs-content personal__content personal__content tabs-content--active">
    <div class="personal__parameters personal__parameters-desk">
      <div class="row">
        <div class="col-lg-6">
          <AddBranch @input="getBranchList()" class="mb-5"/>

          <template v-for="(item, index) in branchList">
            <EditBranch
              class="mb-4"
              :key="index"
              :item="item"
              @delete="getBranchList()"
            />
          </template>
        </div>

        <div class="col-lg-6">
          <AddPrice @input="getPriceList()" class="mb-5"/>

          <template v-for="(item, index) in priceList">
            <EditPrice
              class="mb-4"
              :key="index"
              :item="item"
              @delete="getPriceList()"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import AddBranch from '@/components/AddBranch.vue';
import EditBranch from '@/components/EditBranch.vue';
import AddPrice from '@/components/AddPrice.vue';
import EditPrice from '@/components/EditPrice.vue';

export default {
  name: 'Settings',
  components: {
    AddBranch,
    EditBranch,
    AddPrice,
    EditPrice,
  },
  data() {
    return {
      branchList: [],
      priceList: [],
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    getBranchList() {
      this.branchList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getBranchList');

      axios
        .get(url)
        .then((response) => {
          this.branchList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPriceList() {
      this.priceList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getPriceList');

      axios
        .get(url)
        .then((response) => {
          this.priceList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getBranchList();
    this.getPriceList();
  },
};
</script>
